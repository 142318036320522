import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import { Media } from '../components/Media';
import logo from '../img/grant-murray-logo-wide.svg';
import { Layout, ProjectTypeGrid, GridContainer, GridItem } from '../components';
import Footer from '../components/Footer';

const LogoGridItem = ({ style }) => (
    <div style={{ display: 'flex', ...style }}>
        <img src={logo} alt="Grant Murray" />
    </div>
);

LogoGridItem.propTypes = {
    style: PropTypes.object.isRequired,
};

export const LandingPageTemplate = ({ projectTypes }) => (
    <GridContainer>
        <Media at="mobile">
            <ProjectTypeGrid projects={projectTypes}>
                <LogoGridItem />
                <GridItem
                    as={Link}
                    to={`/${projectTypes[5].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[5].image}`}
                >
                    <div />
                    <h4>{projectTypes[5].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[2].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[2].image}
                >
                    <div />
                    <h4>{projectTypes[2].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[0].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[0].image}`}
                >
                    <div />
                    <h4>{projectTypes[0].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[3].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[3].image}
                >
                    <div />
                    <h4>{projectTypes[3].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[1].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[1].image}
                >
                    <div />
                    <h4>{projectTypes[1].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[4].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[4].image}
                >
                    <div />
                    <h4>{projectTypes[4].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[6].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[6].image}`}
                >
                    <div />
                    <h4>{projectTypes[6].title}</h4>
                </GridItem>
            </ProjectTypeGrid>
        </Media>
        <Media at="portrait">
            <ProjectTypeGrid projects={projectTypes}>
                <GridItem image="landing-page/CORTEN_h8oovr.jpg" triangle="bottom-right" />
                <LogoGridItem style={{ gridColumnStart: 2, gridColumnEnd: 4 }} />
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[0].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[0].image}`}
                >
                    <div />
                    <h4>{projectTypes[0].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[2].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[2].image}
                >
                    <div />
                    <h4>{projectTypes[2].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[3].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[3].image}
                >
                    <div />
                    <h4>{projectTypes[3].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[1].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[1].image}
                >
                    <div />
                    <h4>{projectTypes[1].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[4].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[4].image}
                >
                    <div />
                    <h4>{projectTypes[4].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/${projectTypes[5].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[5].image}`}
                >
                    <div />
                    <h4>{projectTypes[5].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[6].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[6].image}`}
                >
                    <div />
                    <h4>{projectTypes[6].title}</h4>
                </GridItem>
                <GridItem image="landing-page/TRIANGLE_4_nzgtbb.jpg" triangle="top-left" />
            </ProjectTypeGrid>
        </Media>
        <Media at="tablet">
            <ProjectTypeGrid projects={projectTypes}>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[1].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[1].image}
                >
                    <div />
                    <h4>{projectTypes[1].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[4].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[4].image}
                >
                    <div />
                    <h4>{projectTypes[4].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[6].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[6].image}`}
                >
                    <div />
                    <h4>{projectTypes[6].title}</h4>
                </GridItem>
                <GridItem image="landing-page/CORTEN_h8oovr.jpg" triangle="right" />
                <GridItem hide />
                <GridItem hide />
                <GridItem image="landing-page/SKETCH_mqeq8x.jpg" />
                <GridItem
                    as={Link}
                    to={`/${projectTypes[5].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[5].image}`}
                >
                    <div />
                    <h4>{projectTypes[5].title}</h4>
                </GridItem>
                <LogoGridItem style={{ gridColumnStart: 4, gridColumnEnd: 6 }} />
                <GridItem hide />
                <GridItem image="landing-page/CORTEN_h8oovr.jpg" triangle="left" />
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[2].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[2].image}
                >
                    <div />
                    <h4>{projectTypes[2].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[3].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[3].image}
                >
                    <div />
                    <h4>{projectTypes[3].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[0].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[0].image}`}
                >
                    <div />
                    <h4>{projectTypes[0].title}</h4>
                </GridItem>
            </ProjectTypeGrid>
        </Media>
        <Media at="widescreen">
            <ProjectTypeGrid projects={projectTypes}>
                <GridItem image="landing-page/CORTEN_h8oovr.jpg" triangle="left" />
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[1].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[1].image}
                >
                    <div />
                    <h4>{projectTypes[1].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[4].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[4].image}
                >
                    <div />
                    <h4>{projectTypes[4].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[6].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[6].image}`}
                >
                    <div />
                    <h4>{projectTypes[6].title}</h4>
                </GridItem>
                <GridItem image="landing-page/CORTEN_h8oovr.jpg" triangle="right" />
                <GridItem hide />
                <GridItem hide />
                <GridItem hide />
                <GridItem hide />
                <GridItem image="landing-page/SKETCH_mqeq8x.jpg" />
                <GridItem
                    as={Link}
                    to={`/${projectTypes[5].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[5].image}`}
                >
                    <div />
                    <h4>{projectTypes[5].title}</h4>
                </GridItem>
                <LogoGridItem style={{ gridColumnStart: 5, gridColumnEnd: 7 }} />
                <GridItem hide />
                <GridItem hide />
                <GridItem hide />
                <GridItem image="landing-page/GREAT_EASTERN_odgs94.jpg" triangle="left" />
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[2].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[2].image}
                >
                    <div />
                    <h4>{projectTypes[2].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[3].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[3].image}
                >
                    <div />
                    <h4>{projectTypes[3].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[0].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[0].image}`}
                >
                    <div />
                    <h4>{projectTypes[0].title}</h4>
                </GridItem>
                <GridItem image="landing-page/GREAT_EASTERN_odgs94.jpg" triangle="right" />
            </ProjectTypeGrid>
        </Media>
        <Media greaterThanOrEqual="fullhd">
            <ProjectTypeGrid projects={projectTypes}>
                <GridItem image="landing-page/CORTEN_h8oovr.jpg" triangle="left" />
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[1].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[1].image}
                >
                    <div />
                    <h4>{projectTypes[1].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[4].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[4].image}
                >
                    <div />
                    <h4>{projectTypes[4].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[6].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[6].image}`}
                >
                    <div />
                    <h4>{projectTypes[6].title}</h4>
                </GridItem>
                <GridItem image="landing-page/TRIANGLE_3_u5nvut.jpg" triangle="right" />
                <GridItem hide />
                <GridItem hide />
                <GridItem hide />
                <GridItem hide />
                <GridItem image="landing-page/SKETCH_mqeq8x.jpg" />
                <GridItem
                    as={Link}
                    to={`/${projectTypes[5].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[5].image}`}
                >
                    <div />
                    <h4>{projectTypes[5].title}</h4>
                </GridItem>
                <LogoGridItem style={{ gridColumnStart: 5, gridColumnEnd: 7 }} />
                <GridItem hide />
                <GridItem hide />
                <GridItem hide />
                <GridItem image="landing-page/TRIANGLE_4_nzgtbb.jpg" triangle="left" />
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[2].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[2].image}
                >
                    <div />
                    <h4>{projectTypes[2].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[3].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={projectTypes[3].image}
                >
                    <div />
                    <h4>{projectTypes[3].title}</h4>
                </GridItem>
                <GridItem
                    as={Link}
                    to={`/projects/${projectTypes[0].title.replace(/\s+/g, '-').toLowerCase()}`}
                    image={`landing-page/${projectTypes[0].image}`}
                >
                    <div />
                    <h4>{projectTypes[0].title}</h4>
                </GridItem>
                <GridItem image="landing-page/GREAT_EASTERN_odgs94.jpg" triangle="right" />
            </ProjectTypeGrid>
        </Media>
        <Footer />
    </GridContainer>
);

LandingPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    projectTypes: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.string,
            title: PropTypes.string,
        })
    ),
};

const LandingPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;

    return (
        <Layout>
            <LandingPageTemplate
                heroImages={frontmatter.heroImages}
                title={frontmatter.title}
                description={frontmatter.description}
                projectTypes={frontmatter.projectTypes}
                hasNav
            />
        </Layout>
    );
};

LandingPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                heroImages: PropTypes.arrayOf(
                    PropTypes.shape({
                        image: PropTypes.string,
                        title: PropTypes.string,
                    })
                ),
                projectTypes: PropTypes.arrayOf(
                    PropTypes.shape({
                        image: PropTypes.string,
                        title: PropTypes.string,
                    })
                ),
            }),
        }),
    }),
};

export default LandingPage;

export const pageQuery = graphql`
    query LandingPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "landing-page" } }) {
            frontmatter {
                title
                description
                heroImages {
                    image
                    title
                }
                projectTypes {
                    image
                    title
                }
            }
        }
    }
`;
